<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-edutrain-dashboard">
    <lxp-main-header :show-title="true" :show-breadcrumb="true" :show-excel-download="true">
      <template v-slot:action>
        <HistoryYearSelector v-model="yearStr" :show-select-all="true" :yearCnt="6" />
      </template>
    </lxp-main-header>
    <!-- main-content -->
    <div class="main-content min-component">
      <!-- content-section -->
      <section class="content-section section-status">
        <div class="roundedbox-wrap roundedbox-low">
          <!-- roundedbox -->
          <div class="roundedbox">
            <div class="roundedbox-top">
              <div class="top-row">
                <h5 class="roundedbox-title">응시 횟수</h5>
              </div>
            </div>
            <div class="roundedbox-bottom">
              <span class="roundedbox-text">14회</span>
            </div>
          </div>
          <!-- roundedbox -->
          <div class="roundedbox">
            <div class="roundedbox-top">
              <div class="top-row">
                <h5 class="roundedbox-title">응시율</h5>
              </div>
            </div>
            <div class="roundedbox-bottom">
              <span class="roundedbox-text">90%</span>
            </div>
          </div>
          <!-- roundedbox -->
          <div class="roundedbox">
            <div class="roundedbox-top">
              <div class="top-row">
                <h5 class="roundedbox-title">평균 점수</h5>
              </div>
            </div>
            <div class="roundedbox-bottom">
              <span class="roundedbox-text">78.2점</span>
            </div>
          </div>
          <!-- roundedbox -->
          <div class="roundedbox">
            <div class="roundedbox-top">
              <div class="top-row">
                <h5 class="roundedbox-title">총 응시 시간</h5>
              </div>
            </div>
            <div class="roundedbox-bottom">
              <span class="roundedbox-text">6시간 21분</span>
            </div>
          </div>
        </div>
      </section>
      <!-- content-section:E-test 점수 분포도 -->
      <section class="content-section section-chart">
        <header class="section-header">
          <h4 class="title">E-test 점수 분포도</h4>
          <div class="header-column">
            <div class="chart-labels">
              <div class="label"><i class="label-mark mark-primary"></i><span class="label-text">과정평가</span></div>
              <div class="label"><i class="label-mark mark-secondary"></i><span class="label-text">일반평가</span></div>
            </div>
          </div>
        </header>
        <!-- chart-wrap -->

        <div class="chart-wrap">
          <ToastAreaChart :categories="categories" :series="series" />
<!--          &lt;!&ndash; chart &ndash;&gt;-->
<!--          <div class="chart chart-line">-->
<!--            -->
<!--            <img src="../../../assets/lxp/images/@tmp/@tmp_charC_01.png" alt="임시이미지" width="1213" />-->
<!--          </div>-->
        </div>
        <!-- //chart-wrap -->
        <!-- learning-status-wrap -->
        <div class="learning-status-wrap">
          <div class="list-top">
            <div class="top-column align-self-end">
              <p class="title text-muted">총 {{ paging.totalCount }}건</p>
            </div>
            <div class="top-column">
              <div>
                <div class="kb-form-search">
                  <div class="kb-form-search-field">
                    <input v-model="search.testName" type="text" class="kb-form-search-input" placeholder="검색" @keyup.enter="clickSearch"/>
                    <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search">검색</i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="kb-table kb-table-bordered kb-table-row learning-tablelist">
            <table>
              <colgroup>
                <col style="width:70px" />
                <col style="width:110px" />
                <col style="width:110px" />
                <col style="width:auto" />
                <col style="width:250px" />
                <col style="width:90px" />
              </colgroup>
              <thead>
              <tr>
                <th><span class="th-title">평가유형</span></th>
                <th><span class="th-title">평가종류</span></th>
                <th><span class="th-title"></span></th>
                <th><span class="th-title">평가명</span></th>
                <th><span class="th-title">응시날짜</span></th>
                <th><span class="th-title">점수</span></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, idx) in items" :key="idx">
                <td><span class="text">{{ item.testType1 }}</span></td>
                <td><span class="text">{{ item.testType2 }}</span></td>
                <td><span class="text"></span></td>
                <td><strong class="text">{{ item.testName }}</strong></td>
                <td><span class="text">{{ item.applyDate }}</span></td>
                <td><strong class="text">{{ item.examPoint }}점</strong></td>
              </tr>
              </tbody>
            </table>
          </div>
          <CommonPaginationFront :paging="paging" :page-func="pagingFunc" />
        </div>
        <!-- //learning-status-wrap -->
      </section>
    </div>
    <!-- //main-content -->
  </main>
</template>
<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import HistoryYearSelector from '@/components/history/HistoryYearSelector';
import CommonPaginationFront from '@/components/CommonPaginationFront';

import {ACT_GET_FA005AS_LIST} from '@/store/modules/history/history';
import {onBeforeMount, onMounted, reactive, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {getItems, getPaging, initPaging, lengthCheck, setParamsByQueryString} from '@/assets/js/util';
import {useRoute, useRouter} from 'vue-router';
import ToastAreaChart from '@/components/chart/ToastAreaChart';

export default {
  name: 'HistoryEtest',
  components: {
    ToastAreaChart,
    CommonPaginationFront,
    HistoryYearSelector,
    LxpMainHeader
  },
  setup(){

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const items = ref([]);
    const paging = ref(initPaging(route));

    const yearStr = ref(new Date().getFullYear());

    const search = reactive({
      testName:'',
    });

    const getFa005aList = () => {
      store.dispatch(`history/${ACT_GET_FA005AS_LIST}`, {
        testName: search.testName,
        yearStr:yearStr.value,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize,
      }).then(res => {
        if(lengthCheck(res)) {
          items.value = getItems(res);
          paging.value = getPaging(res);
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      });
    }

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo, year: yearStr.value};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      if (search.testName) query.testName = search.testName;
      if(yearStr.value) query.year = yearStr.value;
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1, 10);
    };

    const series = ref([{
      name: '과정평가',
      data: [0, 15, 20, 30, 40, 50, 60, 50, 40, 30,10],
      color: '#ffcc00'
    },
    {
      name: '일반평가',
      data: [0, 20, 25, 40, 25, 20, 15, 10, 5, 3,0],
    }]);

    const categories = ref( [
      '00',
      '10',
      '20',
      '30',
      '40',
      '50',
      '60',
      '70',
      '80',
      '90',
      '100',
    ]);

    watch(() => yearStr.value, () => {
      getFa005aList();
    });

    watch(() => route.query, () => {
      setParamsByQueryString(route, 'HistoryEtest', paging, search, true);
      getFa005aList();
    });

    onBeforeMount(() => {
      setParamsByQueryString(route, 'HistoryEtest', paging, search, true);
    });

    onMounted(() => {
      getFa005aList();
    });

    return {
      items,
      paging,
      search,
      yearStr,

      clickSearch,
      pagingFunc,
      categories,
      series
    }
  }
};
</script>